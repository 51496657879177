import { v4 as uuid } from 'uuid'
import Vue from 'vue'
import Ajv from 'ajv'
import schema from '../schema/seating-plan.schema.json'
import {reverse, SEAT_NUMBERINGS} from "@/lib/numbering";

// This is certainly not a best practice, but we don't want these two reactive for performance reasons
const undoStack = []
const redoStack = []
let timeoutID = null

const zoneNameToID = (name) => name.replace(/[^0-9A-Za-z]/, '')

export default {
	namespaced: true,
	state: {
		_plan: {},
		validationErrors: undefined,
		hasUndo: false,
		hasRedo: false,
	},
	getters: {
		plan (state) {
			return state._plan
		},
		planSize (state) {
			return state._plan.size
		},
		getCategoryByName: (state) => (category) => {
			return state._plan.categories.find(c => c.name === category)
		},
	},
	mutations: {
		loadPlan (state, { plan }) {
			plan = JSON.parse(plan) // Ensure there are no references left
			for (const z of plan.zones) {
				if (!z.uuid) z.uuid = uuid()
				if (z._editor_id) {
					z.zone_id = z._editor_id
					delete z._editor_id
				}
				if (!z.zone_id) z.zone_id = zoneNameToID(z.name)
				for (const r of z.rows) {
					if (!r.uuid) r.uuid = uuid()
					for (const s of r.seats) {
						if (!s.uuid) s.uuid = uuid()
					}
				}
				for (const a of z.areas) {
					if (!a.uuid) a.uuid = uuid()
				}
			}

			state._plan = plan
			undoStack.splice(0, undoStack.length)
			redoStack.splice(0, redoStack.length)
			state.hasUndo = false
			state.hasRedo = false
			this.dispatch('plan/persistPlan')
		},
		setPlanName (state, { name }) {
			state._plan.name = name
			this.dispatch('plan/persistPlan')
		},
		setPlanSize (state, { width, height }) {
			if (width)
				state._plan.size.width = width
			if (height)
				state._plan.size.height = height
			window.dispatchEvent(new Event('resize')) // hack to force re-zoom
			this.dispatch('plan/persistPlan')
		},
		createZone (state, { name, uuid }) {
			state._plan.zones.push({
				name: name,
				zone_id: zoneNameToID(name),
				uuid: uuid,
				position: {x: 0, y: 0},
				rows: [],
				areas: []
			})
			this.dispatch('plan/persistPlan')
		},
		createCategory (state, { name, color }) {
			state._plan.categories.push({
				name: name,
				color: color,
			})
			this.dispatch('plan/persistPlan')
		},
		moveZoneInOrder (state, { uuid, delta }) {
			const zone = state._plan.zones.find(z => z.uuid === uuid)
			const currentIdx = state._plan.zones.findIndex(z => z.uuid === uuid)
			const newIdx = Math.min(Math.max(currentIdx + delta, 0), state._plan.zones.length - 1)
			state._plan.zones.splice(currentIdx, 1)
			state._plan.zones.splice(newIdx, 0, zone)
			this.dispatch('plan/persistPlan')
		},
		changeZone (state, { uuid, name, zone_id }) {
			const zone = state._plan.zones.find(z => z.uuid === uuid)
			zone.name = name
			zone.zone_id = zone_id
			// if the ID changes, we could change ALL seat guids? let's try with not doing it, not sure which way is better
			this.dispatch('plan/persistPlan')
		},
		changeCategory (state, { oldname, newname, color }) {
			const cat = state._plan.categories.find(c => c.name === oldname)
			if (oldname !== newname) {
				if (state._plan.categories.filter(c => c.name === newname).length) {
					// duplicate name, ignore
					// todo: error message
				}
				cat.name = newname
				for (const z of state._plan.zones) {
					for (const r of z.rows) {
						for (const s of r.seats) {
							if (s.category === oldname) {
								s.category = newname
							}
						}
					}
				}
			}
			cat.color = color
			this.dispatch('plan/persistPlan')
		},
		createRow (state, {zone, row, persist}) {
			zone = state._plan.zones.find((z) => z.uuid === zone)
			zone.rows.push(row)
			if (persist) this.dispatch('plan/persistPlan')
		},
		createArea (state, {zone, area}) {
			zone = state._plan.zones.find((z) => z.uuid === zone)
			zone.areas.push(area)
		},
		respaceSeats (state, {rowIds, spacing}) {
			/*
			When changing the seat spacing of a row, we walk the row seat by seat, compute the vector
			[dxOld, dyOld] previously pointing from one seat to the next. Then, we scale the vector so
			its length matches the desired spacing. Then, we again walk the row seat by seat and move
			each seat relative to the previous seat using the scaled vector.

			This approach allows the spacing to happen consistently in a linear row, regardless of how
			the row is rotated.

			This approach might not be good for curved rows, but honestly, there won't be a any
			perfect way to respace curved rows.
			 */
			spacing = Math.max(spacing, 1)
			for (const z of state._plan.zones) {
				for (const r of z.rows) {
					if (rowIds.includes(r.uuid) && r.seats.length) {
						const newpositions = [r.seats[0].position]
						for (let si in r.seats) {
							si = parseInt(si)
							if (si === 0) continue
							const dxOld = r.seats[si].position.x - r.seats[si - 1].position.x
							const dyOld = r.seats[si].position.y - r.seats[si - 1].position.y
							const dOld = Math.sqrt(Math.pow(dxOld, 2) + Math.pow(dyOld, 2))
							const scale = spacing / dOld
							newpositions.push({
								x: newpositions[si - 1].x + dxOld * scale,
								y: newpositions[si - 1].y + dyOld * scale
							})
						}
						for (const si in r.seats) {
							r.seats[si].position = newpositions[si]
						}
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		renumberRows (state, {rowIds, numbering, startAt, reversed}) {
			const rows = []
			for (const z of state._plan.zones) {
				for (const r of z.rows) {
					if (rowIds.includes(r.uuid)) {
						rows.push([z, r])
					}
				}
			}
			const numbers = numbering.compute(rows, startAt)
			for (const [z, r] of rows) {
				r.row_number = reversed ? numbers.pop() : numbers.shift()
				for (const s of r.seats) {
					s.seat_guid = z.zone_id + '-' + r.row_number.toString() + '-' + s.seat_number.toString()
				}
			}
			this.dispatch('plan/persistPlan')
		},
		renumberSeats (state, {rowIds, numbering, startAt, reversed}) {
			for (const z of state._plan.zones) {
				for (const r of z.rows) {
					if (rowIds.includes(r.uuid) && r.seats.length) {
						const numbers = numbering.compute(r.seats, startAt)
						for (const s of r.seats) {
							s.seat_number = reversed ? numbers.pop() : numbers.shift()
							s.seat_guid = z.zone_id + '-' + r.row_number.toString() + '-' + s.seat_number.toString()
						}
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		respaceRows (state, {rowIds, spacing}) {
			/*
			 Re-spacing rows is a lot harder than respacing seats, since we can't assume this to be one-dimensional.
			 We want to be able to re-space rows not only if a block of rows has been rotated in any direction, but
			 also if the rows are shifted, e.g. in a pattern like this:

			 o o o o o o o o o o
			  o o o o o o o o o
			 o o o o o o o o o o

			 To do so properly, we first compute the vector pointing from the first seat of the row to the last seat
			 of the row. We'll call this the "tangent vector", because it will be a tangent to the row if the row is
			 not curved. If the row is curved, it's still the best we can do. We also compute the "normal vector", the
			 row orthogonal to it.

			 Then, we compute the vector between the origin of the first row and the second row ("rowdist"), and split
			 that vector in its component parallel to the normal vector ("rowdist projected on rownormal"), and its
			 component parallel to the tangent vector ("rowdist projected on rowtangent").

			 We then compute the new position of the second row by first applying the tangential component, starting
			 from the origin of the second row, and then apply a component of the normal vector with an absolute value
			 matching the desired spacing between the rows.
			 */
			spacing = Math.max(spacing, 1)
			for (const z of state._plan.zones) {
				const rows = z.rows.filter((r) => rowIds.includes(r.uuid))
				if (rows.length === 0) continue
				const newpositions = [rows[0].position]

				for (let ri in rows) {
					const row = rows[ri]
					ri = parseInt(ri)
					if (!row.seats.length) continue
					if (ri === rows.length - 1) continue

					let rownormal = [
						-row.seats[row.seats.length - 1].position.y - row.seats[0].position.y,
						row.seats[row.seats.length - 1].position.x + row.seats[0].position.x,
					]
					rownormal = [
						rownormal[0] / Math.sqrt(Math.pow(rownormal[0], 2) + Math.pow(rownormal[1], 2)),
						rownormal[1] / Math.sqrt(Math.pow(rownormal[0], 2) + Math.pow(rownormal[1], 2))
					]
					let rowtangent = [
						row.seats[row.seats.length - 1].position.x + row.seats[0].position.x,
						row.seats[row.seats.length - 1].position.y + row.seats[0].position.y,
					]
					rowtangent = [
						rowtangent[0] / Math.sqrt(Math.pow(rowtangent[0], 2) + Math.pow(rowtangent[1], 2)),
						rowtangent[1] / Math.sqrt(Math.pow(rowtangent[0], 2) + Math.pow(rowtangent[1], 2))
					]
					const rowdist = [
						rows[ri + 1].position.x - rows[ri].position.x,
						rows[ri + 1].position.y - rows[ri].position.y,
					]
					const rowdist_projected_on_rownormal = [
						(rownormal[0] * rowdist[0] + rownormal[1] * rowdist[1]) * rownormal[0],
						(rownormal[0] * rowdist[0] + rownormal[1] * rowdist[1]) * rownormal[1],
					]
					const rowdist_projected_on_rowtangent = [
						(rowtangent[0] * rowdist[0] + rowtangent[1] * rowdist[1]) * rowtangent[0],
						(rowtangent[0] * rowdist[0] + rowtangent[1] * rowdist[1]) * rowtangent[1],
					]
					const current_space = Math.sqrt(
						Math.pow(rowdist_projected_on_rownormal[0], 2) + Math.pow(rowdist_projected_on_rownormal[1], 2)
					)
					newpositions.push({
						x: newpositions[ri].x + rownormal[0] * spacing + rowdist_projected_on_rowtangent[0],
						y: newpositions[ri].y + rownormal[1] * spacing + rowdist_projected_on_rowtangent[1]
					})
				}
				for (const ri in rows) {
					rows[ri].position = newpositions[ri]
				}
			}
			this.dispatch('plan/persistPlan')
		},
		addSeat (state, {rowIds}) {
			/*
			 Add one more seat to the end of every selected row.
			 */
			for (const z of state._plan.zones) {
				for (const r of z.rows) {
					if (rowIds.includes(r.uuid) && r.seats.length) {
						let newposition

						// Figure out new position
						if (r.seats.length === 1) {
							newposition = {x: r.seats[0].x + 25, y: r.seats[0].y}
						} else {
							const dx = r.seats[r.seats.length - 1].position.x - r.seats[r.seats.length - 2].position.x
							const dy = r.seats[r.seats.length - 1].position.y - r.seats[r.seats.length - 2].position.y
							newposition = {x: r.seats[r.seats.length - 1].position.x + dx, y: r.seats[r.seats.length - 1].position.y + dy}
						}

						// Try to figure out numbering
						let newnumber = "?"
						for (let numbering of SEAT_NUMBERINGS) {
							try {
								let guessedStartAt = numbering.findStartAt(r.seats[0].seat_number)
								let guessedNumbers = numbering.compute(r.seats, guessedStartAt)
								if (r.seats.filter((s, idx) => s.seat_number === guessedNumbers[idx]).length === r.seats.length) {
									let newNumbers = numbering.compute(r.seats.concat([{}]), guessedStartAt)
									newnumber = newNumbers[newNumbers.length - 1]
									break
								}

								let seatsReversed = reverse(r.seats)
								let guessedStartAtRev = numbering.findStartAt(seatsReversed[0].seat_number)
								let guessedNumbersRev = numbering.compute(seatsReversed, guessedStartAtRev)
								if (seatsReversed.filter((s, idx) => s.seat_number === guessedNumbersRev[idx]).length === r.seats.length) {
									let newNumbers = numbering.compute(seatsReversed.concat([{}]), guessedStartAtRev)
									for (const s of r.seats) {
										s.seat_number = newNumbers.pop()
										s.seat_guid = z.zone_id + '-' + r.row_number.toString() + '-' + s.seat_number.toString()
									}
									newnumber = newNumbers[0]
									break
								}
							} catch (e) {
								console.warn('Crash while trying to test seat numbering schema', numbering, e)
							}
						}

						r.seats.push({
							seat_number: newnumber,
							seat_guid: z.zone_id + '-' + r.row_number.toString() + '-' + newnumber,
							uuid: uuid(),
							position: newposition,
							category: r.seats[r.seats.length - 1].category
						})
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		modifyRows (state, {rowIds, ...args}) {
			for (const z of state._plan.zones) {
				for (const r of z.rows) {
					if (rowIds.includes(r.uuid)) {
						for (const arg in args) {
							if (arg.includes('__')) {
								let inner = r
								const argparts = arg.split('__')
								for (const argpart in argparts) {
									if (parseInt(argpart) === argparts.length - 1) {
										Vue.set(inner, argparts[argpart], args[arg])
									} else {
										inner = inner[argparts[argpart]]
									}
								}
							} else {
								Vue.set(r, arg, args[arg])
							}
						}
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		modifySeats (state, {seatIds, ...args}) {
			for (const z of state._plan.zones) {
				for (const r of z.rows) {
					for (const s of r.seats) {
						if (seatIds.includes(s.uuid)) {
							for (const arg in args) {
								if (arg.includes('__')) {
									let inner = s
									const argparts = arg.split('__')
									for (const argpart in argparts) {
										if (parseInt(argpart) === argparts.length - 1) {
											Vue.set(inner, argparts[argpart], args[arg])
										} else {
											inner = inner[argparts[argpart]]
										}
									}
								} else {
									Vue.set(s, arg, args[arg])
								}
							}
						}
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		modifyAreas (state, {areaIds, ...args}) {
			for (const z of state._plan.zones) {
				for (const a of z.areas) {
					if (areaIds.includes(a.uuid)) {
						for (const arg in args) {
							if (arg.includes('__')) {
								let inner = a
								const argparts = arg.split('__')
								for (const argpart in argparts) {
									if (parseInt(argpart) === argparts.length - 1) {
										Vue.set(inner, argparts[argpart], args[arg])
									} else {
										inner = inner[argparts[argpart]]
									}
								}
							} else {
								Vue.set(a, arg, args[arg])
							}
						}
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		restackAreas (state, {areaIds, front}) {
			for (const z of state._plan.zones) {
				for (const a of z.areas) {
					if (areaIds.includes(a.uuid)) {
						z.areas = z.areas.filter((a_) => a.uuid !== a_.uuid)
						if (front) {
							z.areas.push(a)
						} else {
							z.areas.unshift(a)
						}
					}
				}
			}
			this.dispatch('plan/persistPlan')
		},
		deleteCategory (state, {name}) {
			state._plan.categories = state._plan.categories.filter((z) => z.name !== name)
			this.dispatch('plan/persistPlan')
		},
		deleteObjects (state, {objects}) {
			state._plan.zones = state._plan.zones.filter((z) => !objects.includes(z.uuid))
			for (const z of state._plan.zones) {
				z.areas = z.areas.filter((a) => !objects.includes(a.uuid))
				z.rows = z.rows.filter((r) => !objects.includes(r.uuid))
				for (const r of z.rows) {
					r.seats = r.seats.filter((s) => !objects.includes(s.uuid))
				}
			}
			this.commit('unselect', {uuids: objects})
			this.dispatch('plan/persistPlan')
		},

		recordHistoryState (state, json) {
			undoStack.push(json)
			if (undoStack.length > 100) {
				undoStack.shift()
			}
			redoStack.splice(0, redoStack.length)
			state.hasUndo = undoStack.length >= 2
			state.hasRedo = false
		},
		undo (state) {
			if (undoStack.length >= 2) {
				const plan = undoStack.pop()
				redoStack.push(plan)
				state._plan = JSON.parse(undoStack[undoStack.length - 1])
				state.hasRedo = true
				state.hasUndo = undoStack.length >= 2
				this.dispatch('plan/persistPlan', {skipHistory: true})
			}
		},
		redo (state) {
			if (redoStack.length) {
				const plan = redoStack.pop()
				undoStack.push(plan)
				state._plan = JSON.parse(plan)
				state.hasUndo = undoStack.length >= 2
				state.hasRedo = redoStack.length >= 1
				this.dispatch('plan/persistPlan', {skipHistory: false})
			}
		},
		setValidationErrors(state, {data}) {
			state.validationErrors = data
		},
	},
	actions: {
		persistPlan (context, payload) {
			const s = JSON.stringify(this.state.plan._plan)
			localStorage.setItem('frontrow2.editor.plan', s)
			if (typeof payload === "undefined" || payload.skipHistory !== true) {
				this.commit('plan/recordHistoryState', s)
			}

			this.commit('plan/setValidationErrors', {data: undefined})
			const $store = this
			if (timeoutID !== null) {
				window.clearTimeout(timeoutID)
			}
			timeoutID = window.setTimeout(() => {
				$store.dispatch('plan/validatePlan')
				timeoutID = null
			}, 500)
		},
		validatePlan () {
			const ajv = new Ajv({ allErrors: true, verbose: true })
			const plan = this.state.plan._plan
			Promise.resolve(ajv.validate(JSON.parse(JSON.stringify(schema)), plan)).then((valid) => {
				if (valid) {
					const errors = []
					const seatGuids = new Set()
					const seatNames = new Set()
					const errorIds = new Set()

					for (const z of plan.zones) {
						if (errors.length > 50) break
						for (const r of z.rows) {
							if (errors.length > 50) break
							for (const s of r.seats) {
								if (errors.length > 50) break
								const sname = `${z.name}>${r.row_number}>${s.seat_number}`
								if (!s.seat_guid) {
									errors.push({
										text: `Seat ${escape(z.name)} > ${r.row_number} > ${s.seat_number} has no seat ID.`,
										uuid: s.uuid,
										tool: 'seatselect',
									})
								} else if (seatGuids.has(s.seat_guid) && !errorIds.has(s.seat_guid)) {
									errors.push({
										text: `Seat ID "${s.seat_guid}" is not unique! This will lead to errors when people try to book these seats. (Seen again in "${z.name}" > "${r.row_number}" > "${s.seat_number}")`,
										uuid: s.uuid,
										tool: 'seatselect',
									})
									errorIds.add(s.seat_guid)
								} else if (seatNames.has(sname) && !errorIds.has(sname)) {
									errors.push({
										text: `You have multiple seats with zone "${z.name}", row "${r.row_number}", and seat "${s.seat_number}". This is going to be very confusing.`,
										uuid: s.uuid,
										tool: 'seatselect',
									})
									errorIds.add(sname)
								}
								seatGuids.add(s.seat_guid)
								seatNames.add(sname)
							}
						}
					}

					this.commit('plan/setValidationErrors', {data: errors})
				} else {
					this.commit('plan/setValidationErrors', {data: [{text: 'JSON schema validation error (contact support for help): ' + ajv.errorsText(ajv.errors)}]})
				}
			})
		},
		createRowBlock (context, {zone, position, rows, seats, row_spacing, seat_spacing}) {
			const rowids = []
			zone = this.state.plan._plan.zones.find((z) => z.uuid === zone)
			for (const rix of [...Array(rows).keys()]) {
				const row = {
					position: {x: position.x, y: position.y + rix * row_spacing},
					row_number: (rix+1).toString(),
					row_number_position: 'both',
					seats: [],
					uuid: uuid(),
				}
				for (const six of [...Array(seats).keys()]) {
					row.seats.push({
						seat_number: (six+1).toString(),
						seat_guid: uuid(),
						uuid: uuid(),
						position: {x: six * seat_spacing, y: 0},
						category: ''
					})
				}
				context.commit('createRow', {zone: zone.uuid, row, persist: false})
				rowids.push(row.uuid)
			}
			context.dispatch('persistPlan')
			return rowids
		},
		createRow (context, {zone, position, seats}) {
			const rowids = []
			zone = this.state.plan._plan.zones.find((z) => z.uuid === zone)
			const row = {
				position: {x: position.x, y: position.y},
				row_number: "1",
				row_number_position: 'both',
				seats: [],
				uuid: uuid(),
			}
			for (const [six, spos] of seats.entries()) {
				row.seats.push({
					seat_number: (six + 1).toString(),
					seat_guid: uuid(),
					uuid: uuid(),
					position: {x: spos.x, y: spos.y},
					category: ''
				})
			}
			context.commit('createRow', {zone: zone.uuid, row, persist: false})
			context.dispatch('persistPlan')
			return row.uuid
		},
		createArea (context, {zone, area}) {
			context.commit('createArea', {zone, area})
			this.dispatch('plan/persistPlan')
		},
	}
}
