<template lang="pug">
prompt.c-create-zone-prompt(@close="$emit('close')")
	.content
		h1 Create a new category
		form(@submit.prevent="create")
			bunt-input(name="name", label="Name", placeholder="Category X", v-model="name", :validation="$v.name")
			chrome(:value="color", @input="color = $event.hex")
			bunt-button(type="submit") Create
</template>
<script>
import Prompt from './Prompt'
import { required } from 'lib/validators'
import { v4 as uuid } from 'uuid'
import {mapGetters} from 'vuex'
import { Chrome } from 'vue-color'

const COLORS = [
	'#F44336',
	'#9C27B0',
	'#4CAF50',
	'#2196F3',
	'#8BC34A',
	'#00BCD4',
	'#FF9800',
	'#795548',
]
export default {
	components: { Prompt, Chrome },
	data () {
		return {
			name: '',
			color: '#000000',
		}
	},
	validations: {
		name: {
			required: required('Name is required')
		},
		color: {
			required: required('Color is required')
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),
	},
	methods: {
		create () {
			this.$v.$touch()
			if (this.$v.$invalid) return
			if (this.plan.categories.filter(c => c.name === this.name).length) {
				alert('Please do not use the same name twice')
				return
			}
			let id = uuid()
			this.$store.commit('plan/createCategory', {
				name: this.name,
				color: this.color
			})
			this.$emit('close')
		}
	},
	mounted () {
		let usedColors = this.plan.categories.map(c => c.color)
		let unusedColors = COLORS.filter((c) => !usedColors.includes(c))
		if (unusedColors.length) {
			this.color = unusedColors[0]
		}
	}
}
</script>
<style lang="stylus">
.c-create-zone-prompt
	.content
		display: flex
		flex-direction: column
		padding: 32px
		position: relative
		h1
			margin: 0
			text-align: center
		p
			max-width: 320px
		form
			display: flex
			flex-direction: column
			align-self: stretch
			.bunt-button
				button-style(color: $clr-primary)
				margin-top: 16px
</style>
