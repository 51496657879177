<template lang="pug">
.c-toolbox

	.shape(v-if="selection.length && selectedAreas.length")
		.group-title
			span Shape
		area-tools(:areas="selectedAreas")

	.row(v-if="selection.length && selectedRows.length")
		.group-title
			span Row
		row-tools(:rows="selectedRows")

	.seat(v-if="selection.length && selectedSeats.length")
		.group-title
			span Seat
		seat-tools(:seats="selectedSeats")

	.plan(v-if="!selection.length && plan.size")
		.group-title
			span Plan
		plan-tools(:seats="Object.values(categoryStats).reduce((a, b) => a + b, 0)")

	hr(v-if="!selection.length")

	.categories(v-if="!selection.length")
		.group-title
			span Categories
			.buffer
			bunt-icon-button(@click="showCreateCategoryPrompt = true") plus
		category(v-for="c in plan.categories", :category="c", :seats="categoryStats[c.name]")

	hr(v-if="!selection.length")

	.categories(v-if="!selection.length")
		.group-title
			span Background picture
		p The background you upload here will not be saved as part of your plan, it is just useful for placing objects on the plan.
		.bg(v-if="$parent.$refs.plan.background")
			.toolbox-row
				label Opacity (%)
				number-input.toolbox-input(:value="$parent.$refs.plan.backgroundOpacity", name="opacity", @input="$parent.$refs.plan.backgroundOpacity = $event; $parent.$refs.plan.persistBackground()")
			.toolbox-row
				label Width
				number-input.toolbox-input(:value="$parent.$refs.plan.backgroundWidth", name="width", @input="$parent.$refs.plan.backgroundWidth = $event; $parent.$refs.plan.persistBackground()")
			.toolbox-row
				label Height
				number-input.toolbox-input(:value="$parent.$refs.plan.backgroundHeight", name="height", @input="$parent.$refs.plan.backgroundHeight = $event; $parent.$refs.plan.persistBackground()")
			.toolbox-row
				label Position (x)
				number-input.toolbox-input(:value="$parent.$refs.plan.backgroundX", name="x", @input="$parent.$refs.plan.backgroundX = $event; $parent.$refs.plan.persistBackground()")
			.toolbox-row
				label Position (y)
				number-input.toolbox-input(:value="$parent.$refs.plan.backgroundY", name="y", @input="$parent.$refs.plan.backgroundY = $event; $parent.$refs.plan.persistBackground()")
			.toolbox-row
				bunt-button(@click="$parent.$refs.plan.background = null; $parent.$refs.plan.persistBackground()") Remove background
		.toolbox-row(v-else)
			bunt-button(@click="bgUpload") Upload image

	transition(name="prompt")
		create-category-prompt(v-if="showCreateCategoryPrompt", @close="showCreateCategoryPrompt = false")
</template>
<script>
import {mapGetters} from 'vuex'
import Category from './Category'
import PlanTools from './PlanTools'
import AreaTools from './AreaTools'
import SeatTools from './SeatTools'
import RowTools from './RowTools'
import CreateCategoryPrompt from '../prompts/CreateCategoryPrompt'
import NumberInput from './fields/NumberInput'

export default {
	components: { Category, PlanTools, AreaTools, RowTools, SeatTools, CreateCategoryPrompt, NumberInput },
	data () {
		return {
			showCreateCategoryPrompt: false
		}
	},
	computed: {
		...mapGetters(['selection', 'tool']),
		...mapGetters('plan', ['plan']),

		categoryStats () {
			const counter = {}
			for (const z of this.plan.zones) {
				for (const r of z.rows) {
					for (const s of r.seats) {
						counter[s.category] = (counter[s.category] || 0) + 1
					}
				}
			}
			return counter
		},

		selectedAreas () {
			const r = []
			if (this.selection.length) {
				for (const z of this.plan.zones) {
					for (const a of z.areas) {
						if (this.selection.includes(a.uuid))
							r.push(a)
					}
				}
			}
			return r
		},
		selectedSeats () {
			const res = []
			if (this.selection.length) {
				for (const z of this.plan.zones) {
					for (const r of z.rows) {
						for (const s of r.seats) {
							if (this.selection.includes(r.uuid) || this.selection.includes(s.uuid)) {
								res.push(s)
							}
						}
					}
				}
			}
			return res
		},
		selectedRows () {
			const res = []
			if (this.selection.length) {
				for (const z of this.plan.zones) {
					for (const r of z.rows) {
						if (this.selection.includes(r.uuid)) {
							res.push(r)
						}
					}
				}
			}
			return res
		},
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		bgUpload () {
			const input = document.createElement('input')
			input.type = 'file'
			input.accept = '.jpg,.jpeg,.png,.gif,.bmp'
			input.onchange = (e) => {
				const fileReader = new FileReader()
				fileReader.onload = () => {
					this.$parent.$refs.plan.background = fileReader.result
					this.$parent.$refs.plan.backgroundWidth = this.plan.size.width
					this.$parent.$refs.plan.backgroundHeight = this.plan.size.height
					this.$parent.$refs.plan.persistBackground()
				}
				fileReader.readAsDataURL(input.files[0])
			}
			input.click()
		}
	}
}
</script>
<style lang="stylus">
	.c-toolbox
		p
			padding: 10px 16px
			margin: 0
			font-size: 12px
			color: #666
	.toolbox-row
		display: flex
		flex-direction: row
		padding: 2px 16px
		& > label
			flex: 0 0
			width: 40%
			flex-basis: 40%
			padding: 8px 0
		span.toolbox-input
			display: block
			padding-top: 8px
			text-align: center
			flex: 1
		.spanner
			flex: auto
		.toolbox-input, .toolbox-input.dropdown .bunt-input
			padding-top: 0
			flex-shrink: 1
			input-style(size: 'compact')
			.bunt-switch
				margin-top: 8px
				margin-bottom: 0
</style>
