<template lang="pug">
.bunt-input.c-number-input(:class!="{focused, invalid, disabled}", v-resize-observer="generateOutline")
	.label-input-container
		bunt-icon-button(
			@pointerdown.native="btnPointerDown($event, -1)",
			@pointerup.native="btnPointerUp",
		) minus
		input(
			ref="input", type="text", :name="name", :value="value", :disabled="disabled", :readonly="readonly", @input="onInput", @focus="focused = true", @blur="onBlur",
			@keydown.arrow-up="onArrowKeysDown($event, 1)",
			@keydown.arrow-down="onArrowKeysDown($event, -1)",
			@pointerdown="onPointerDown",
			@pointerup="onPointerUp",
			@pointermove.prevent="onPointerMove",
		)
		.error-icon.mdi.mdi-alert-circle(v-show="invalid")
		bunt-icon-button(
			@pointerdown.native="btnPointerDown($event, 1)",
			@pointerup.native="btnPointerUp",
		) plus
		svg.outline(ref="outline")
			path(:d="outlineStroke")
</template>
<script>
import inputOutline from 'buntpapier/src/mixins/input-outline'

export default {
	mixins: [inputOutline],
	props: {
		value: Number,
		name: String,
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		validation: Object // vuelidate result
	},
	data () {
		return {
			focused: false,
			currentX: 0,
			pointerDownTimeout: null,
			shiftKeyDown: false,
			altKeyDown: false,
		}
	},
	computed: {
		invalid () {
			return this.validation && this.validation.$error
		},
	},
	methods: {
		onInput (event) {
			let val = event.target.value
			if (this.validation) this.validation.$touch()
			val = val.replace(',', '.')
			if (isNaN(parseFloat(val))) {
				this.$emit('input', 0)
			} else {
				this.$emit('input', parseFloat(val))
			}
		},
		onBlur () {
			this.focused = false
			if (this.validation) this.validation.$touch()
		},
		onArrowKeysDown(event, d) {
			// keep default behaviour of arrow-up/down (moving to start/end of input.value)
			// and only if step value if selection is on start/end
			if ((d > 0 && this.$refs.input.selectionStart == 0) || (d < 0 && this.$refs.input.selectionEnd == this.$refs.input.value.length)) {
				this.shiftKeyDown = event.shiftKey
				this.altKeyDown = event.altKey
				this.step(d)
			}
		},
		globalKeyDown (event) {
			if (event.key === "Shift") this.shiftKeyDown = true;
			if (event.key === "Alt") this.altKeyDown = true;
		},
		globalKeyUp (event) {
			if (event.key === "Shift") this.shiftKeyDown = false;
			if (event.key === "Alt") this.altKeyDown = false;
		},
		step (d) {
			this.$emit(
				'input',
				parseFloat(this.value ? this.value : 0) + d * (this.shiftKeyDown ? 10 : (this.altKeyDown ? .1 : 1))
			)
		},
		stepRepeatedly(d, interval=250) {
			this.step(d)
			// increase step-speed by reducing timeout-interval stepwise down to 50ms
			this.pointerDownTimeout = window.setTimeout(this.stepRepeatedly, interval, d, Math.max(interval-35, 50))
		},
		// +- Buttons execute step repeatedly while hold down
		// listen for keydown/up of shift-key while pressed down
		btnPointerDown (event, d) {
			window.addEventListener('keydown', this.globalKeyDown)
			window.addEventListener('keyup', this.globalKeyUp)
			this.shiftKeyDown = event.shiftKey
			this.stepRepeatedly(d)
		},
		btnPointerUp (event) {
			this.shiftKeyDown = false
			window.removeEventListener('keydown', this.globalKeyDown)
			window.removeEventListener('keyup', this.globalKeyUp)
			window.clearTimeout(this.pointerDownTimeout);
		},
		// onPointer<x>-methods are all about changing value by dragging left/right
		onPointerDown (event) {
			// change value by dragging left/right - wait 250ms before activating dragging so that
			// „normal“ text-selection by mouse works
			// instead of binding pointer/mouse-movement to window, use setPointerCapture on target
			// see https://codepen.io/patrickhlauke/pen/VapvZV
			const t = event.target
			this.pointerDownTimeout = window.setTimeout(function () {
				t.setPointerCapture(event.pointerId)
				t.style.cursor = 'ew-resize'
				// if text inside number-field is selected, this would trigger OS-wide drag'n'drop of text
				// => unselect test inside and make input-text temporarily not-selectable
				t.style.userSelect = 'none'
				t.selectionEnd = t.selectionStart
			}, 250)
			this.currentX = event.clientX
		},
		onPointerUp (event) {
			if (this.pointerDownTimeout) window.clearTimeout(this.pointerDownTimeout)
			/* no need to explicitly removePointerCapture */
			event.target.style.removeProperty('cursor')
			event.target.style.removeProperty('user-select')
		},
		onPointerMove (event) {
			// to change a number with the mouse, one has to click and wait til timeout has passed
			// this is to not interfere with text selection
			// so if clicked and pointer is moved before timeout passed, then abort activating pointercapture
			if (this.pointerDownTimeout) window.clearTimeout(this.pointerDownTimeout)

			const t = event.target
			if (t.hasPointerCapture && t.hasPointerCapture(event.pointerId)) {
				const dx = event.clientX - this.currentX
				const stepdistance = 5
				if (Math.abs(dx) > stepdistance) {
					const dvalue = dx < 0 ? Math.ceil(dx / stepdistance) : Math.floor(dx / stepdistance)
					this.currentX += dvalue * stepdistance
					//this.$emit('input', (parseFloat(this.value ? this.value : 0) + (event.shiftKey ? 10 : 1) * dvalue))
					this.shiftKeyDown = event.shiftKey
					this.step(dvalue)
				}
			}
		}
	}
}
</script>
<style lang="stylus">
	.c-number-input
		align-items: center
		input
			text-align: center
			width: 119px !important  // i can't get flexbox to work in firefox, so hackhack :(
</style>
