<template lang="pug">
.c-seat-tools
	.toolbox-row
		label Radius
		number-input.toolbox-input(:value="radius", name="radius", @input="setRadius($event)")
	.toolbox-row(v-if="seats.length === 1")
		label Seat number
			| &nbsp;
			span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'This is the number of the seat that will be shown to users.'")
		bunt-input.toolbox-input(:value="seatNumber", name="seat_number", @input="setSeatNumber($event)")
	.toolbox-row(v-if="seats.length === 1")
		label Seat ID
			| &nbsp;
			span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'This is the internal number of the seat, used to keep track of which seats are purchased. In a plan that is already in use, this should never change.'")
		bunt-input.toolbox-input(:value="seatId", name="seat_id", @input="setSeatId($event)")
	.toolbox-row
		label Category
			| &nbsp;
			span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'The category defines the color of the seat, and the product that will be sold when selecing the seat in the shop.'")
		bunt-select.toolbox-input(:value="category", name="category", @input="setCategory($event)", :options="[{color: '#fff', name: ''}].concat(this.plan.categories)" option-value="name" option-label="name")
			template(slot-scope="{ option }")
				span.color-indicator(:style="'background: ' + option.color")
				span {{ option.name }}
	.toolbox-row(v-if="tool == 'seatselect'")
		label Optimizer hint
			| &nbsp;
			span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'This allows the ticket shop to know which seats to sell first. Use > on the first seat of a row and < on the last seat, for example, or <> on a middle seat..'")
		bunt-select.toolbox-input(:value="startDirection", name="start_direction", @input="setStartDirection($event)", :options="start_directions")
</template>
<script>

import {mapGetters, mapMutations} from "vuex";
import NumberInput from "./fields/NumberInput";

const groupValue = (seats, mapper) => {
	const uniques = seats.map(mapper).filter((v, i, s) => s.indexOf(v) === i)
	if (uniques.length === 1) {
		return uniques[0]
	} else {
		return undefined
	}
}

export default {
	components: {NumberInput},
	props: {
		seats: Array,
	},
	data () {
		return {
			start_directions: [
				'',
				'>',
				'<>',
				'<'
			]
		}
	},
	computed: {
		...mapGetters(['tool']),
		...mapGetters('plan', ['plan']),

		radius () {
			return groupValue(this.seats, s => s.radius ? s.radius : 10)
		},
		category () {
			return groupValue(this.seats, s => s.category)
		},
		seatNumber () {
			return groupValue(this.seats, s => s.seat_number)
		},
		seatId () {
			return groupValue(this.seats, s => s.seat_guid)
		},
		startDirection () {
			return groupValue(this.seats, s => s.start_direction ? s.start_direction : '')
		},
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		...mapMutations('plan', ['modifySeats']),

		setRadius(val) {
			this.modifySeats({seatIds: this.seats.map(s => s.uuid), radius: val})
		},
		setCategory(val) {
			this.modifySeats({seatIds: this.seats.map(s => s.uuid), category: val})
		},
		setSeatNumber(val) {
			this.modifySeats({seatIds: this.seats.map(s => s.uuid), seat_number: val})
		},
		setSeatId(val) {
			this.modifySeats({seatIds: this.seats.map(s => s.uuid), seat_guid: val})
		},
		setStartDirection(val) {
			this.modifySeats({seatIds: this.seats.map(s => s.uuid), start_direction: val})
		},
	}
}
</script>
<style lang="stylus">
.c-seat-tools
	.color-indicator
		display: inline-block
		width: 16px
		height: 16px
		margin-right: 4px
		border-radius: 100%
		border: 1px solid #333
		position: relative
		top: 5px
</style>
