<template lang="pug">
.c-plan-tools
	.toolbox-row
		label Name
		bunt-input.toolbox-input(:value="plan.name", name="name", @input="setPlanName({name: $event})")
	.toolbox-row
		label Width
		number-input.toolbox-input(:value="plan.size.width", name="width", @input="setPlanSize({width: $event})")
	.toolbox-row
		label Height
		number-input.toolbox-input(:value="plan.size.height", name="height", @input="setPlanSize({height: $event})")
	.toolbox-row
		label Number of seats
		span.toolbox-input {{ seats }}
</template>
<script>

import {mapGetters, mapMutations} from "vuex";
import NumberInput from "./fields/NumberInput";

export default {
	components: {NumberInput},
	props: {
		category: Object,
		seats: Number,
	},
	data () {
		return {
			expanded: false,
			w: 0
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		...mapMutations('plan', ['setPlanSize', 'setPlanName']),
	}
}
</script>
<style lang="stylus">
</style>
