<template lang="pug">
.c-toolbar
	.group
		bunt-icon-button(tooltip="Start a new plan", @click="reset") file-outline
		bunt-icon-button(tooltip="Load plan", @click="upload") folder-open
		bunt-icon-button(tooltip="Save plan", @click="download") content-save
		bunt-icon-button(tooltip="Export to PDF", @click="exportPdf") file-pdf-box
		bunt-icon-button.validation(tooltip="Validation", @click="showValidationResult = true") {{ validationErrors === undefined ? 'loading mdi-spin mdi-color-muted' : (validationErrors.length === 0 ? 'check-circle-outline mdi-color-green' : 'alert-octagon mdi-color-red') }}
	.group
		bunt-icon-button(tooltip="Select rows or shapes [V]", @click="changeTool('select')", :class="tool == 'select' ? 'current-tool' : ''") cursor-default
		bunt-icon-button.tool-seatselect(tooltip="Select seats [S]", @click="changeTool('seatselect')", :class="tool == 'seatselect' ? 'current-tool' : ''") -fr-custom
		bunt-icon-button.tool-rows(tooltip="Add rows [B]", @click="changeTool('rows')", :class="tool == 'rows' ? 'current-tool' : ''") -fr-custom
		bunt-icon-button.tool-row(tooltip="Add row [N]", @click="changeTool('row')", :class="tool == 'row' ? 'current-tool' : ''") -fr-custom
		bunt-icon-button(tooltip="Add rectangle [R]", @click="changeTool('rectangle')", :class="tool == 'rectangle' ? 'current-tool' : ''") shape-rectangle-plus
		bunt-icon-button(tooltip="Add circle [C]", @click="changeTool('circle')", :class="tool == 'circle' ? 'current-tool' : ''") shape-circle-plus
		bunt-icon-button(tooltip="Add ellipse [E]", @click="changeTool('ellipse')", :class="tool == 'ellipse' ? 'current-tool' : ''") shape-oval-plus
		bunt-icon-button(tooltip="Add polygon [P]", @click="changeTool('polygon')", :class="tool == 'polygon' ? 'current-tool' : ''") shape-polygon-plus
		bunt-icon-button.tool-text(tooltip="Add text [T]", @click="changeTool('text')", :class="tool == 'text' ? 'current-tool' : ''") -fr-custom
	.group
		bunt-icon-button(tooltip="Undo [Ctrl+Z]", @click="undo", :disabled="!hasUndo") undo
		bunt-icon-button(tooltip="Redo [Ctrl+Y]", @click="redo", :disabled="!hasRedo") redo
	.group
		bunt-icon-button(:disabled="!selection.length", @click="cut", tooltip="Cut [Ctrl+X]") content-cut
		bunt-icon-button(:disabled="!selection.length", @click="copy", tooltip="Copy [Ctrl+C]") content-copy
		bunt-icon-button(:disabled="!clipboardAreas.length && !clipboardRows.length", @click="paste", tooltip="Paste [Ctrl+V]") content-paste
		bunt-icon-button(:disabled="!selection.length", @click.prevent="deleteObjects", tooltip="Delete object [Del]") delete
	.group
		bunt-icon-button(tooltip="Zoom out", @click="zoomOut") magnify-minus-outline
		bunt-button(@click="zoomOne") {{ Math.round(zoomTransform.k * 100) }}%
		bunt-icon-button(tooltip="Zoom in", @click="zoomIn") magnify-plus-outline
	.group
		bunt-icon-button(tooltip="Toggle grid", @click="toggleGrid") {{ grid ? 'grid-off' : 'grid' }}

	transition(name="prompt")
		validation-result-prompt(v-if="showValidationResult", @close="showValidationResult = false")
</template>
<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import store from '../store'
import sampleplan from '../sampleplan'
import * as d3 from 'd3'
import Ajv from 'ajv'
import schema from '../schema/seating-plan.schema.json'
import ValidationResultPrompt from "./prompts/ValidationResultPrompt"
import jsPDF from "jspdf"
import 'svg2pdf.js'  // do not remove

export default {
	components: { ValidationResultPrompt },
	data () {
		return {
			showValidationResult: false
		}
	},
	computed: {
		...mapGetters(['selection', 'tool', 'zoomTransform']),
		...mapGetters('plan', ['plan']),
		...mapState(['grid', 'clipboardAreas', 'clipboardRows']),
		...mapState('plan', ['hasUndo', 'hasRedo', 'validationErrors']),
	},
	watch: {},
	created () {
	},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		...mapMutations(['toggleGrid', 'disableGrid']),
		...mapMutations('plan', ['undo', 'redo']),
		upload () {
			const input = document.createElement('input')
			input.type = 'file'
			input.accept = '.json'
			input.onchange = (e) => {
				const fileReader = new FileReader()
				fileReader.onload = () => {
					let data
					try {
						data = JSON.parse(fileReader.result)
					} catch {
						alert('Failed to load file (not a JSON file)')
						return
					}
					const ajv = new Ajv({
						allErrors: true,
						verbose: true,
					})
					Promise.resolve(ajv.validate(schema, data)).then((valid) => {
						if (valid || ajv.errors.every((e) => e.keyword === "additionalProperties")) {
							store.commit('loadPlan', {
								plan: fileReader.result
							})
						} else {
							console.log(ajv.errors)
							alert('Failed to load file (invalid seating plan)')
						}
					})
				}
				fileReader.readAsText(input.files[0])
			}
			input.click()
		},
		download () {
			if (this.validationErrors === undefined || this.validationErrors.length === 0 || confirm('Your plan contains validation errors. Do you still want to download it?')) {
				const url = URL.createObjectURL(new Blob([JSON.stringify(this.plan, undefined, 2)]))
				const a = document.createElement('a')
				a.style.display = 'none'
				a.href = url
				a.download = this.plan.name + '.json'
				document.body.appendChild(a)
				a.click()
				URL.revokeObjectURL(url)
			} else {
				this.showValidationResult = true
			}
		},
		exportPdf () {
			this.$store.commit('setZoomTransform', d3.zoomIdentity)
			this.disableGrid()

			window.setTimeout(() => {
				const doc = new jsPDF(
					this.plan.size.width > this.plan.size.height ? 'l' : 'p',
					'pt',
					[this.plan.size.width, this.plan.size.height]
				)
				const element = this.$parent.$refs.plan.$refs.svg
				doc
					.svg(element, {
						width: this.plan.size.width,
						height: this.plan.size.height,
					})
					.then(() => {
						doc.save(this.plan.name + '.pdf', {
							returnPromise: true
						}).then(() => {
							window.dispatchEvent(new Event('resize')) // hack to force re-zoom
						})
					})
			}, 500)
		},
		zoomIn () {
			d3.select(this.$parent.$refs.plan.$refs.svg)
				.transition().call(this.$parent.$refs.plan.zoom.scaleBy, 1.2)
		},
		zoomOut () {
			d3.select(this.$parent.$refs.plan.$refs.svg)
				.transition().call(this.$parent.$refs.plan.zoom.scaleBy, 0.8)
		},
		zoomOne () {
			if (this.zoomTransform.k === this.$parent.$refs.plan.defaultScale) {
				d3.select(this.$parent.$refs.plan.$refs.svg)
					.transition().call(this.$parent.$refs.plan.zoom.scaleTo, 1)
			} else {
				d3.select(this.$parent.$refs.plan.$refs.svg)
					.transition().call(this.$parent.$refs.plan.zoom.scaleTo, this.$parent.$refs.plan.defaultScale)
			}
		},

		reset () {
			if (window.confirm('Do you want to start a new plan? Your current plan will be discarded.')) {
				store.commit('loadPlan', {
					plan: JSON.stringify(sampleplan.sampleplan)
				})
			}
		},
		cut () {
			if (this.tool === 'seatselect') {
				alert('Copying individual seats is currently not possible, please select a row instead.')
				return
			}
			this.$store.commit('cut', {objects: this.selection})
		},
		copy () {
			if (this.tool === 'seatselect') {
				alert('Copying individual seats is currently not possible, please select a row instead.')
				return
			}
			this.$store.commit('copy', {objects: this.selection})
		},
		paste () {
			this.$store.commit('paste')
		},
		deleteObjects () {
			this.$store.commit('plan/deleteObjects', {objects: this.selection})
		},
		changeTool (tool) {
			this.$store.commit('changeTool', {tool})
		}
	}
}
</script>
<style lang="stylus">
	.c-toolbar
		width: 100%
		flex-direction: row
		display: flex

		.group
			margin-right: 15px
			padding-right: 15px
			display: flex
			flex-direction: row
			border-right: 1px solid #ccc

		.current-tool
			border: 1px solid $clr-primary
		.mdi
			color: black
			opacity: .735 // yields almost the same color as the native icons
		.validation .mdi
			opacity: 1
		.mdi--fr-custom::before
			content: " "
			width: 20px
			height: 36px
			background-position: center
			background-repeat: no-repeat
		.tool-seatselect .mdi--fr-custom::before
			background-image: url("../assets/tool-seatselect.svg")
		.tool-text .mdi--fr-custom::before
			background-image: url("../assets/tool-text.svg")
		.tool-rows .mdi--fr-custom::before
			background-image: url("../assets/tool-rows.svg")
		.tool-row .mdi--fr-custom::before
			background-image: url("../assets/tool-row.svg")

</style>
