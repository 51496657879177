<template lang="pug">
prompt.c-change-zone-prompt(@close="$emit('close')")
	.content
		h1 Change zone
		form(@submit.prevent="save")
			bunt-input(name="name", label="Name", placeholder="Ground floor", v-model="name", :validation="$v.name")
			bunt-input(name="editor_id", label="ID for seat IDs", placeholder="GroundFloor", v-model="zone_id", :validation="$v.zone_id", hint="Should not be changed once the plan is in use.")
			bunt-button(type="submit") Save
</template>
<script>
import Prompt from './Prompt'
import { required } from 'lib/validators'
import {mapGetters} from 'vuex'
import { Chrome } from 'vue-color'

export default {
	components: { Prompt, Chrome },
	data () {
		return {
			name: '',
			zone_id: '',
		}
	},
	validations: {
		name: {
			required: required('Name is required')
		},
		zone_id: {
			required: required('Color is required')
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),
	},
	methods: {
		save () {
			this.$v.$touch()
			if (this.$v.$invalid) return
			this.$emit('save')
		}
	},
}
</script>
<style lang="stylus">
.c-change-zone-prompt
	.content
		display: flex
		flex-direction: column
		padding: 32px
		position: relative
		h1
			margin: 0
			text-align: center
		p
			max-width: 320px
		form
			display: flex
			flex-direction: column
			align-self: stretch
			.bunt-button
				button-style(color: $clr-primary)
				margin-top: 16px
</style>
