<template lang="pug">
.c-status-bar
	span.hint(v-if="selection.length") {{ selection.length }} objects selected
	span(v-if="selectionIsPolygon")
		span.hint Move nodes by dragging
		span.hint Click blue squares to add nodes
		span.hint Middle-click nodes to delete them
	span(v-if="(tool == 'seatselect' && selection.length === 2)")
		span.hint Seat distance: {{ seatDistance }}
	span(v-if="(tool == 'seatselect' || tool == 'select')")
		span(v-if="dragging")
			span.hint Hold down <code>SHIFT</code> to snap to grid positions
		span(v-else-if="$parent.$refs.plan.rotating")
			span.hint Hold down <code>SHIFT</code> to only rotate in 5° steps
		span(v-else-if="selection.length")
			span.hint Press <code>SHIFT</code> and click to (de)select more objects
			span.hint Press arrow keys to move objects (hold <code>SHIFT</code> to move fast, <code>ALT</code> to move slow)
			span.hint Click and drag to move objects
		span(v-else)
			span.hint Click any object to select it
			span.hint(v-if="$parent.$refs.plan && (zoomTransform.k !== $parent.$refs.plan.defaultScale)") Click + drag while pressing #[code {{ $parent.cmdOtherwiseCtrl }}] to move around
			span.hint(v-else) Press #[code {{ $parent.cmdOtherwiseCtrl }}] and scroll to zoom
	span(v-else-if="(tool == 'row')")
		span(v-if="$parent.$refs.plan.rowDrawing")
			span.hint Move your mouse and click again once the correct number of seats is visible
			span.hint Press <code>ESC</code> to abort
			span.hint Drawing {{ $parent.$refs.plan.rowDrawingSeats.length }} seats
		span(v-else)
			span.hint Click on the position of the first seat to draw
			span.hint Hold down <code>SHIFT</code> to choose a position on the grid
		span.hint Spacing {{ $parent.$refs.plan.rowSeatSpacing.toFixed(1) }}, press <code>+</code>/<code>-</code> to vary (<code>ALT</code> for smaller steps)
	span(v-else-if="(tool == 'rowCircle' || tool == 'rowCircleFixedCenter')")
		span.hint Press <code>SHIFT</code> to snap to grid
		span.hint Click to apply
	span(v-else-if="(tool == 'rows')")
		span(v-if="$parent.$refs.plan.rowBlockDrawing")
			span.hint Move your mouse and click again once the correct number of seats is visible
			span.hint Press <code>ESC</code> to abort
			span.hint Drawing {{ $parent.$refs.plan.rowBlockRows * $parent.$refs.plan.rowBlockSeats }} seats
		span(v-else)
			span.hint Click on the position of the first seat to draw
			span.hint Hold down <code>SHIFT</code> to choose a position on the grid
		span.hint Spacing {{ $parent.$refs.plan.rowSpacing.toFixed(1) }}x{{ $parent.$refs.plan.rowSeatSpacing.toFixed(1) }}, press (<code>CTRL</code>)<code>+</code>/<code>-</code> to vary (<code>ALT</code> for smaller steps)
	span(v-else-if="(tool == 'rectangle') || (tool == 'circle') || (tool == 'ellipse')")
		span(v-if="$parent.$refs.plan.drawing")
			span.hint Move your mouse to start drawing, release it once you're done
			span.hint Hold down <code>SHIFT</code> to limit movement to grid positions
			span.hint Press <code>ESC</code> to abort
		span(v-else)
			span.hint Click and drag to draw a new shape
			span.hint Hold down <code>SHIFT</code> to only start at grid positions
	span(v-else-if="(tool == 'polygon')")
		span(v-if="$parent.$refs.plan.polygonDrawing")
			span.hint Click to add another point
			span.hint Press <code>ENTER</code> or double-click to finish
			span.hint Hold down <code>SHIFT</code> to limit angles to 5° steps
			span.hint Press <code>ESC</code> to abort
		span(v-else)
			span.hint Click to add the first point of your polygon
			span.hint Hold down <code>SHIFT</code> to choose a position on the grid
	span(v-else-if="(tool == 'text')")
		span.hint Click anywhere to add a new text object
</template>
<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import store from '../store'
import sampleplan from '../sampleplan'
import * as d3 from 'd3'
import Ajv from 'ajv'
import * as schema from '../schema/seating-plan.schema.json'
import ValidationResultPrompt from "./prompts/ValidationResultPrompt";

export default {
	data () {
		return {
		}
	},
	computed: {
		...mapState(['tool', 'selection', 'zoomTransform', 'dragging']),
		...mapGetters('plan', ['plan']),

		selectionIsPolygon () {
			if (!this.plan && !this.plan.zones) return false;
			for (const z of this.plan.zones) {
				for (const r of z.rows) {
					if (this.selection.includes(r.uuid)) {
						return false;
					}
					for (const s of r.seats) {
						if (this.selection.includes(s.uuid)) {
							return false;
						}
					}
				}
				for (const a of z.areas) {
					if (this.selection.includes(a.uuid) && a.shape !== 'polygon') {
						return false;
					}
				}
			}
			return this.selection.length === 1
		},
		seatDistance () {
			let p1 = null
			for (const z of this.plan.zones) {
				for (const r of z.rows) {
					for (const s of r.seats) {
						if (this.selection.includes(s.uuid)) {
							if (p1 === null) {
								p1 = [
									z.position.x + r.position.x + s.position.x,
									z.position.y + r.position.y + s.position.y,
								]
							} else {
								const p2 = [
									z.position.x + r.position.x + s.position.x,
									z.position.y + r.position.y + s.position.y,
								]
								const d = Math.sqrt(
									Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2)
								)
								return d.toFixed(3)
							}
						}
					}
				}
			}
			return "?"
		}
	},
}
</script>
<style lang="stylus">
	.c-status-bar
		code
			display: inline-block
			border: 1px solid #888
			border-radius: 2px
			padding: 1px
			position: relative
			top: -1px
		span.hint
			display: inline-block
			margin-right: 15px
</style>
