<template lang="pug">
g.zone(:transform="transform", :opacity="opacity", :pointer-events="lockedZones.includes(zone.uuid) ? 'none' : 'visiblePainted'")
	zone-area(v-for="a in zone.areas", :area="a", :key="a.uuid", :zone="zone", @startDragging="startDragging", @startDraggingPolygonPoint="startDraggingPolygonPoint")
	row(v-for="r in zone.rows", :row="r", :key="r.uuid", :zone="zone", @startDragging="startDragging")

</template>
<script>
import Row from './Row'
import ZoneArea from './ZoneArea'
import {mapState} from "vuex";

export default {
	components: { Row, ZoneArea },
	props: {
		zone: Object,
	},
	data () {
		return {
			expanded: false
		}
	},
	computed: {
		...mapState(['lockedZones']),
		transform () {
			return `translate(${this.zone.position.x}, ${this.zone.position.y})`
		},
		opacity () {
			return this.zone.uuid === this.$store.state.selectedZone ? 1 : 0.7
		}
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		startDragging (uuid, zone, event) {
			this.$emit('startDragging', uuid, zone, event)
		},
		startDraggingPolygonPoint (uuid, pid, zone, event) {
			this.$emit('startDraggingPolygonPoint', uuid, pid, zone, event)
		},
	}
}
</script>
<style lang="stylus">
</style>
