<template lang="pug">
g.area(:transform="transform", @mousedown="mousedown", @mouseup="mouseup", :class="classes")
	circle(v-if="area.shape === 'circle'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" cx="0", cy="0", :r="area.circle.radius", stroke-width="2px")
	ellipse(v-if="area.shape === 'ellipse'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" cx="0", cy="0", :rx="area.ellipse.radius.x", :ry="area.ellipse.radius.y", stroke-width="2px")
	rect(v-if="area.shape === 'rectangle'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" x="0", y="0", :width="area.rectangle.width", :height="area.rectangle.height", stroke-width="2px")
	polygon(v-if="area.shape === 'polygon'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" x="0", y="0", :points="polygonPoints", stroke-width="2px")
	text(v-if="area.text.text", :x="area.text.position.x", :y="area.text.position.y", :font-size="area.text.size ? area.text.size : 16", text-anchor="middle", font-family="sans-serif", dy=".3em", :fill="area.text.color ? area.text.color : '#888888'", ref="text") {{ area.text.text }}
	g(v-if="area.shape === 'polygon' && area.polygon && area.polygon.points && isIndividuallySelected")
		rect.polygon-point-handle(v-for="(p, pid) in area.polygon.points", :x="p.x - 3", :y="p.y - 3" width="6", height="6", @mousedown="mousedownPolygonPoint($event, pid)")
		rect.polygon-new-point-handle(v-for="(p, pid) in area.polygon.points", :x="(p.x + area.polygon.points[pid ? pid - 1 : area.polygon.points.length - 1].x) / 2 - 2", :y="(p.y + area.polygon.points[pid ? pid - 1 : area.polygon.points.length - 1].y) / 2 - 2" width="4", height="4", @mousedown="insertPolygonPointBefore($event, pid)")

</template>
<script>
import {mapGetters} from "vuex";
import {positionInZone} from "../../lib/geometry";

export default {
	props: {
		area: Object,
		zone: null,
	},
	data () {
		return {
			ignoreNextMouseUp: false
		}
	},
	computed: {
		...mapGetters(['cursor']),
		isSelected () {
			return this.$store.state.selection.includes(this.area.uuid)
		},
		isIndividuallySelected () {
			return this.$store.state.selection.length === 1 && this.isSelected
		},
		classes () {
			var classes = {
				'selected': this.isSelected
			}
			classes['area-' + this.area.shape] = true
			return classes
		},
		transform () {
			if (this.area.rotation) {
				return `translate(${this.area.position.x}, ${this.area.position.y}) rotate(${this.area.rotation})`
			}
			return `translate(${this.area.position.x}, ${this.area.position.y})`
		},
		polygonPoints () {
			return this.area.polygon.points.map(point => `${point.x},${point.y}`).join(' ')
		}
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		ignore (event) {
			event.stopPropagation()
			return true
		},
		mouseup (event) {
			if (event.ctrlKey || event.metaKey) {
				// this is a panning event
				return false
			}
			if (this.ignoreNextMouseUp) {
				this.ignoreNextMouseUp = false
				return true
			}
			if (this.$store.state.tool === 'select') {
				if (!this.$store.state.dragged) {
					this.$store.commit('toggleSelection', {uuids: [this.area.uuid], addition: event.shiftKey, zone: this.zone.uuid})
				}
				if (this.$store.state.dragging) {
					this.$store.commit('stopDragging')
				}
				return true
			}
			return false
		},
		insertPolygonPointBefore (event, pid) {
			if (event.ctrlKey || event.metaKey) {
				// this is a panning event
				return false
			}
			if (this.$store.state.tool === 'select' || this.$store.state.tool === 'polygon') {
				this.area.polygon.points.splice(pid, 0, {
					x: (this.area.polygon.points[pid].x + this.area.polygon.points[pid ? pid - 1 : this.area.polygon.points.length - 1].x) / 2,
					y: (this.area.polygon.points[pid].y + this.area.polygon.points[pid ? pid - 1 : this.area.polygon.points.length - 1].y) / 2,
				})
				this.$store.dispatch('plan/persistPlan')
				this.ignoreNextMouseUp = true
				event.stopPropagation()
				return true
			}
			return false
		},
		mousedownPolygonPoint (event, pid) {
			if (event.ctrlKey || event.metaKey) {
				// this is a panning event
				return false
			}
			if (this.$store.state.tool === 'select' || this.$store.state.tool === 'polygon') {
				if (event.which === 2) {
					// middle click deletes point
					this.area.polygon.points.splice(pid, 1)
					this.$store.dispatch('plan/persistPlan')
					this.ignoreNextMouseUp = true
				} else {
					this.$emit('startDraggingPolygonPoint', this.area.uuid, pid, this.zone, event)
					this.ignoreNextMouseUp = false
				}
				event.stopPropagation()
				return true
			}
			return false
		},
		mousedown (event) {
			if (event.ctrlKey || event.metaKey) {
				// this is a panning event
				return false
			}
			if (this.$store.state.tool === 'select') {
				this.$emit('startDragging', this.area.uuid, this.zone, event)
				this.ignoreNextMouseUp = false
				event.stopPropagation()
				return true
			}
			return false
		},
	}
}
</script>
<style lang="stylus">
	.movable g.area.selected, .movable g.area.selected *
		cursor: move
	.polygon-point-handle
		stroke: #00c
		stroke-width: 2px
		fill: #fff
		cursor: grabbing !important
	.polygon-new-point-handle
		stroke: #00c
		stroke-width: 2px
		fill: #00c
		cursor: copy !important
</style>
