function luminanace (r, g, b) {
	var a = [r, g, b].map(function (v) {
		v /= 255
		return v <= 0.03928
			? v / 12.92
			: Math.pow((v + 0.055) / 1.055, 2.4)
	})
	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

function contrast (rgb1, rgb2) {
	const l1 = luminanace(rgb1[0], rgb1[1], rgb1[2]) + 0.05
	const l2 = luminanace(rgb2[0], rgb2[1], rgb2[2]) + 0.05
	let ratio = l1 / l2
	if (l2 > l1) {
		ratio = 1 / ratio
	}
	return ratio
}

function hex2rgb (hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b
	})

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16)
	] : null
}

export {contrast, hex2rgb}
