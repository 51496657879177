<template lang="pug">
.c-tree-zone
	a(:class="selectedZone == zone.uuid ? 'selected' : ''", @click="select")
		span.mdi(:class="lockedZones.includes(zone.uuid) ? 'mdi-lock' : 'mdi-layers-outline'", @click.prevent="selectAllInZone", v-tooltip="'Select all elements in zone'")
		span.name {{ zone.name }}
		.zone-tools
			bunt-icon-button(v-if="plan.zones.length > 1", @click.prevent="up") arrow-up
			bunt-icon-button(v-if="plan.zones.length > 1", @click.prevent="down") arrow-down
			bunt-icon-button(@click.prevent="change") pencil
			bunt-icon-button(@click.prevent="lock") {{ lockedZones.includes(zone.uuid) ? 'lock-open' : 'lock' }}
			bunt-icon-button(v-if="plan.zones.length > 1", @click.prevent="remove") delete
	transition(name="prompt")
		change-zone-prompt(v-if="showChangeZonePrompt", ref="changeprompt", @save="changed", @close="showChangeZonePrompt=false")

</template>
<script>

import {mapGetters, mapState} from 'vuex'
import ChangeZonePrompt from './prompts/ChangeZonePrompt'

export default {
	components: { ChangeZonePrompt },
	props: {
		zone: Object,
	},
	data () {
		return {
			showChangeZonePrompt: false,
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),
		...mapState(['selectedZone', 'lockedZones']),
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		change (e) {
			this.showChangeZonePrompt = true
			this.$nextTick(() => {
				this.$refs.changeprompt.name = this.zone.name
				this.$refs.changeprompt.zone_id = this.zone.zone_id
			})
			e.stopPropagation()
			return true
		},
		changed (e) {
			this.$store.commit('plan/changeZone', {
				uuid: this.zone.uuid,
				name: this.$refs.changeprompt.name,
				zone_id: this.$refs.changeprompt.zone_id
			})
			this.showChangeZonePrompt = false
		},
		lock () {
			this.$store.commit('toggleZoneLock', {uuid: this.zone.uuid})
		},
		selectAllInZone () {
			this.$store.commit('selectAllInZone', {uuid: this.zone.uuid})
		},
		select () {
			this.$store.commit('selectZone', {uuid: this.zone.uuid})
		},
		up (e) {
			this.$store.commit('plan/moveZoneInOrder', {
				uuid: this.zone.uuid,
				delta: -1
			})
			e.stopPropagation()
			return true
		},
		down (e) {
			this.$store.commit('plan/moveZoneInOrder', {
				uuid: this.zone.uuid,
				delta: 1
			})
			e.stopPropagation()
			return true
		},
		remove (e) {
			if (window.confirm('Do you really want to delete this zone including all it\'s sets, rows and shapes?')) {
				if (this.zone.uuid === this.selectedZone) {
					let selecting = this.plan.zones.filter((z) => z.uuid !== this.zone.uuid)[0].uuid
					this.$store.commit('selectZone', {uuid: selecting})
				}
				this.$store.commit('plan/deleteObjects', {objects: [this.zone.uuid]})
			}
			e.stopPropagation()
			return true
		},
	}
}
</script>
<style lang="stylus">
	.c-tree-zone
		width: 100%
		position: relative
		span.mdi
			margin-right: 4px
			padding: 10px 0
		span.name
			display: inline-block
			padding: 10px 0
		a
			display: flex
			padding: 0px 16px
			cursor: pointer
			font-weight: bold
		a.selected
			background: #ccc
		.zone-tools
			visibility: hidden
			position: absolute
			right: 0
			top: 0
			background: rgba(255, 255, 255, 0.6)
		a:hover .zone-tools
			visibility: visible
</style>
