<template lang="pug">
.c-row-tools
	.toolbox-row(v-if="rows.length > 1")
		label Row spacing
			| &nbsp;
		number-input.toolbox-input(:value="rowSpacing", name="row_spacing", @input="setRowSpacing($event)")
	.toolbox-row
		label Seat spacing
		number-input.toolbox-input(:value="seatSpacing", name="seat_spacing", @input="setSeatSpacing($event)")
	.toolbox-row(v-if="rows.length == 1")
		label Row number
			| &nbsp;
			span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'This number will be use to show the user which seat they book. Once the plan is in use, it should not change it any more.'")
		bunt-input.toolbox-input(:value="rowNumber", name="row_number", @input="setRowNumber($event)")
	.toolbox-row(v-if="rows.length == 1")
		label Row label
			| &nbsp;
			span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'You can set this to modify how the row is printed on the ticket, e.g. you can make the ticket say \"Table 3\" instead of \"Row 3\". This is optional!'")
		bunt-input.toolbox-input(:value="rowLabel", name="row_label", @input="setRowLabel($event)", :placeholder="'Row ' + (rowNumber || '?')")
	.toolbox-row
		label Show row numbers
		bunt-checkbox.toolbox-input(:value="rowNumberPositionStart", @input="setRowNumberPositionStart($event)", name="row_number_position_start")
		.spanner
		bunt-checkbox.toolbox-input(:value="rowNumberPositionEnd", @input="setRowNumberPositionEnd($event)", name="row_number_position_end")
	.toolbox-row
		bunt-icon-button.tool-add-seat(tooltip="Add seat", @click="addSeat_") -fr-custom
		bunt-icon-button(@click="changeTool('rowCircle')", tooltip="Align on circle (by radius)", :class="tool == 'rowCircle' ? 'current-tool' : ''") vector-circle
		bunt-icon-button(@click="changeTool('rowCircleFixedCenter')", tooltip="Align on circle (by center)", :class="tool == 'rowCircleFixedCenter' ? 'current-tool' : ''") vector-circle-variant
	.row-numbers(v-if="rows.length > 1")
		.group-title
			span Row numbers
		.toolbox-row
			label Numbering
			bunt-select.toolbox-input(:options="rowNumberings", :value="rowNumbering ? rowNumbering.scheme.id : null", @input="setRowNumbering($event)", option-label="label", name="row_numbering")
		.toolbox-row
			label Starting at
			number-input.toolbox-input(:value="rowNumbering ? rowNumbering.startAt : null", name="row_numbering_start_at", @input="setRowNumberingStartAt($event)")
		.toolbox-row
			label Reversed
			.toolbox-input
				bunt-switch(:value="rowNumbering ? rowNumbering.reversed : false", @input="setRowNumberingReversed($event)", name="row_numbering_reversed")
	.seat-numbers
		.group-title
			span Seat numbers
		.toolbox-row
			label Numbering
			bunt-select.toolbox-input(:options="seatNumberings", :value="seatNumbering ? seatNumbering.scheme.id : null", @input="setSeatNumbering($event)", option-label="label", name="seat_numbering")
		.toolbox-row
			label Starting at
			number-input.toolbox-input(:value="seatNumbering ? seatNumbering.startAt : null", name="seat_numbering_start_at", @input="setSeatNumberingStartAt($event)")
		.toolbox-row
			label Reversed
			.toolbox-input
				bunt-switch(:value="seatNumbering ? seatNumbering.reversed : false", @input="setSeatNumberingReversed($event)", name="seat_numbering_reversed")
		.toolbox-row
			label Seat label
				| &nbsp;
				span.mdi.mdi-help-circle.tooltip-large(v-tooltip.top="'You can set this to modify how the seats in this row are printed on the ticket, e.g. you can make the ticket say \"Couch 3\" instead of \"Seat 3\". Use \"%s\" for the seat number. This is optional!'")
			bunt-input.toolbox-input(:value="seatLabel", name="seat_label", @input="setSeatLabel($event)", :placeholder="seatLabel === undefined ? '' : 'Seat %s'")
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import NumberInput from "./fields/NumberInput";
import {reverse, ROW_NUMBERINGS, SEAT_NUMBERINGS} from "../../lib/numbering";

const shallowEqual = (object1, object2) => {
	if (object1 === null || object2 === null || typeof object1 !== "object" || typeof object2 !== "object") {
		return object1 === object2
	}
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}

const groupValue = (rows, mapper) => {
	let lastFound = undefined
	for (let row of rows) {
		let val = mapper(row)
		if (lastFound === undefined) {
			lastFound = val
		} else if (!shallowEqual(lastFound, val)) {
			return undefined
		}
	}
	return lastFound
}

const round = (fl, places) => Number(fl.toFixed(places ? places : 0))

export default {
	components: {NumberInput},
	props: {
		rows: Array,
	},
	data () {
		return {
			seatNumberings: SEAT_NUMBERINGS,
			rowNumberings: ROW_NUMBERINGS,
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),
		...mapGetters(['tool']),

		seatSpacing () {
			// Look into the respaceSeats mutator for a description of the math happening here
			return groupValue(this.rows, row => {
				let minspace = null
				for (let si in row.seats) {
					if (parseInt(si) === 0) continue
					let space = round(Math.sqrt(
						Math.pow(row.seats[si].position.x - row.seats[si - 1].position.x, 2) +
						Math.pow(row.seats[si].position.y - row.seats[si - 1].position.y, 2)
					), 2)
					if (minspace === null || space < minspace) {
						minspace = space
					}
				}
				return minspace ? minspace : 25
			})
		},
		rowSpacing () {
			// Look into the respaceRows mutator for a description of the math happening here
			let allspace = null
			for (let ri in this.rows) {
				let row = this.rows[ri]
				ri = parseInt(ri)
				if (!row.seats.length) continue
				if (ri === this.rows.length - 1) continue

				let rownormal = [
					-row.seats[row.seats.length - 1].position.y - row.seats[0].position.y,
					row.seats[row.seats.length - 1].position.x + row.seats[0].position.x,
				]
				rownormal = [
					rownormal[0] / Math.sqrt(Math.pow(rownormal[0], 2) + Math.pow(rownormal[1], 2)),
					rownormal[1] / Math.sqrt(Math.pow(rownormal[0], 2) + Math.pow(rownormal[1], 2))
				]
				let rowdist = [
					this.rows[ri + 1].position.x - this.rows[ri].position.x,
					this.rows[ri + 1].position.y - this.rows[ri].position.y,
				]
				let rowdist_projected_on_rownormal = [
					(rownormal[0] * rowdist[0] + rownormal[1] * rowdist[1]) * rownormal[0],
					(rownormal[0] * rowdist[0] + rownormal[1] * rowdist[1]) * rownormal[1],
				]
				let space = round(Math.sqrt(
					Math.pow(rowdist_projected_on_rownormal[0], 2) + Math.pow(rowdist_projected_on_rownormal[1], 2)
				), 2)
				if (allspace === null) {
					allspace = space
				} else if (allspace !== space) {
					allspace = undefined
				}
			}
			return allspace
		},

		seatNumbering () {
			return groupValue(this.rows, row => {
				for (let numbering of SEAT_NUMBERINGS) {
					try {
						let guessedStartAt = numbering.findStartAt(row.seats[0].seat_number)
						let guessedNumbers = numbering.compute(row.seats, guessedStartAt)
						if (row.seats.filter((s, idx) => s.seat_number === guessedNumbers[idx]).length === row.seats.length) {
							return {scheme: numbering, reversed: false, startAt: guessedStartAt}
						}

						let seatsReversed = reverse(row.seats)
						let guessedStartAtRev = numbering.findStartAt(seatsReversed[0].seat_number)
						let guessedNumbersRev = numbering.compute(seatsReversed, guessedStartAtRev)
						if (seatsReversed.filter((s, idx) => s.seat_number === guessedNumbersRev[idx]).length === row.seats.length) {
							return {scheme: numbering, reversed: true, startAt: guessedStartAtRev}
						}
					} catch (e) {
						console.warn('Crash while trying to test seat numbering schema', numbering, e)
					}
				}
				return undefined
			})
			// skip ?
		},
		rowNumbering() {
			for (let numbering of ROW_NUMBERINGS) {
				try {
					let guessedStartAt = numbering.findStartAt(this.rows[0].row_number)
					let guessedNumbers = numbering.compute(this.rows, guessedStartAt)
					if (this.rows.filter((r, idx) => r.row_number === guessedNumbers[idx]).length === this.rows.length) {
						return {scheme: numbering, reversed: false, startAt: guessedStartAt}
					}

					let rowsReversed = reverse(this.rows)
					let guessedStartAtRev = numbering.findStartAt(rowsReversed[0].row_number)
					let guessedNumbersRev = numbering.compute(rowsReversed, guessedStartAtRev)
					if (rowsReversed.filter((r, idx) => r.row_number === guessedNumbersRev[idx]).length === this.rows.length) {
						return {scheme: numbering, reversed: true, startAt: guessedStartAtRev}
					}
				} catch (e) {
					console.warn('Crash while trying to test row numbering schema', numbering, e)
				}
			}
			return undefined
		},

		rowNumber () {
			return groupValue(this.rows, row => row.row_number)
		},
		rowNumberPositionStart () {
			return groupValue(this.rows, row => row.row_number_position === 'start' || row.row_number_position === 'both')
		},
		rowNumberPositionEnd () {
			return groupValue(this.rows, row => row.row_number_position === 'end' || row.row_number_position === 'both')
		},
		rowLabel () {
			return groupValue(this.rows, row => (row.row_label || ''))
		},
		seatLabel () {
			return groupValue(this.rows, row => (row.seat_label || ''))
		},
	},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		...mapMutations('plan', ['respaceSeats', 'respaceRows', 'renumberSeats', 'renumberRows', 'modifyRows', 'addSeat']),

		setRowNumber (val) {
			this.modifyRows({rowIds: this.rows.map(r => r.uuid), row_number: val})
		},
		setRowLabel (val) {
			this.modifyRows({rowIds: this.rows.map(r => r.uuid), row_label: val ? val : null})
		},
		setSeatLabel (val) {
			this.modifyRows({rowIds: this.rows.map(r => r.uuid), seat_label: val ? val : null})
		},
		setSeatSpacing (val) {
			this.respaceSeats({rowIds: this.rows.map(r => r.uuid), spacing: val})
		},
		setRowSpacing (val) {
			this.respaceRows({rowIds: this.rows.map(r => r.uuid), spacing: val})
		},
		addSeat_ () {
			this.addSeat({rowIds: this.rows.map(r => r.uuid)})
		},
		setSeatNumbering (val) {
			let numbering = SEAT_NUMBERINGS.find(n => n.id === val)
			this.renumberSeats({rowIds: this.rows.map(r => r.uuid), numbering: numbering, startAt: 1, reversed: false})
		},
		setSeatNumberingStartAt (val) {
			if (this.seatNumbering) {
				this.renumberSeats({rowIds: this.rows.map(r => r.uuid), numbering: this.seatNumbering.scheme, startAt: val, reversed: this.seatNumbering.reversed})
			}
		},
		setSeatNumberingReversed (val) {
			if (this.seatNumbering) {
				this.renumberSeats({rowIds: this.rows.map(r => r.uuid), numbering: this.seatNumbering.scheme, startAt: this.seatNumbering.startAt, reversed: val})
			}
		},
		setRowNumbering (val) {
			let numbering = ROW_NUMBERINGS.find(n => n.id === val)
			this.renumberRows({rowIds: this.rows.map(r => r.uuid), numbering: numbering, startAt: 1, reversed: false})
		},
		setRowNumberingStartAt (val) {
			if (this.rowNumbering) {
				this.renumberRows({rowIds: this.rows.map(r => r.uuid), numbering: this.rowNumbering.scheme, startAt: val, reversed: this.rowNumbering.reversed})
			}
		},
		setRowNumberingReversed (val) {
			if (this.seatNumbering) {
				this.renumberRows({rowIds: this.rows.map(r => r.uuid), numbering: this.rowNumbering.scheme, startAt: this.rowNumbering.startAt, reversed: val})
			}
		},
		setRowNumberPositionStart (val) {
			if (this.rowNumberPositionEnd)
				this.modifyRows({rowIds: this.rows.map(r => r.uuid), row_number_position: val ? 'both' : 'end'})
			else
				this.modifyRows({rowIds: this.rows.map(r => r.uuid), row_number_position: val ? 'start' : null})
		},
		setRowNumberPositionEnd (val) {
			if (this.rowNumberPositionStart)
				this.modifyRows({rowIds: this.rows.map(r => r.uuid), row_number_position: val ? 'both' : 'start'})
			else
				this.modifyRows({rowIds: this.rows.map(r => r.uuid), row_number_position: val ? 'end' : null})
		},
		changeTool (tool) {
			this.$store.commit('changeTool', {tool})
			document.activeElement.blur()
		},
	}
}
</script>
<style lang="stylus">
.c-row-tools
	.color-indicator
		display: inline-block
		width: 16px
		height: 16px
		margin-right: 4px
		border-radius: 100%
		border: 1px solid #333
		position: relative
		top: 5px
	.current-tool
		border: 1px solid $clr-primary
	.mdi--fr-custom::before
		content: " "
		width: 20px
		height: 36px
		background-position: center
		background-repeat: no-repeat
	.tool-add-seat .mdi--fr-custom::before
		background-image: url("../../assets/tool-add-seat.svg")
</style>
