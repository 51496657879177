<template lang="pug">
g(@mousedown="mousedown", @mouseup="mouseup", :class="classObject")
	circle(:fill="seatColor", :cx="seat.position.x", :cy="seat.position.y", :r="radius", :stroke="category ? 'none' : '#000'", stroke-width="1px")
	text(:fill="textColor", :x="seat.position.x", :y="seat.position.y", text-anchor="middle", font-size="10px", font-family="sans-serif", dy=".3em") {{ textContent }}

</template>
<script>
import {contrast, hex2rgb} from '../../lib/colors'
import {mapGetters} from 'vuex'

export default {
	props: {
		seat: Object,
		zone: Object,
	},
	data () {
		return {
			lastMouseUp: 0,
		}
	},
	computed: {
		// eslint-disable-next-line no-undef
		...mapGetters('plan', ['getCategoryByName']),
		...mapGetters(['cursor']),
		classObject () {
			return {
				'seat': true,
				'selected': this.$store.state.selection.includes(this.seat.uuid)
			}
		},
		textContent () {
			let content = this.seat.seat_number
			if (this.seat.flag) {
				content = this.seat.flag
			}
			if (this.seat.start_direction) {
				content = this.seat.start_direction
			}
			return content
		},
		category () {
			return this.getCategoryByName(this.seat.category)
		},
		radius () {
			if (this.seat.radius !== undefined) {
				return this.seat.radius
			} else {
				return 10
			}
		},
		seatColor () {
			if (this.category) {
				return this.category.color
			} else {
				return '#ffffff'
			}
		},
		textColor () {
			if (this.category) {
				const c = contrast([0, 0, 0], hex2rgb(this.category.color))
				if (c < 5)
					return '#FFFFFF'
				else
					return '#000000'
			} else {
				return '#666666'
			}
		},
	},
	watch: {},
	methods: {
		mouseup (event) {
			if (event.ctrlKey || event.metaKey) {
				// this is a panning event
				return false
			}
			const interval = (new Date()).getTime() - this.lastMouseUp
			this.lastMouseUp = (new Date()).getTime()
			if (this.$store.state.tool === 'seatselect') {
				if (!this.$store.state.dragged) {
					this.$store.commit('toggleSelection', {uuids: [this.seat.uuid], addition: event.shiftKey, zone: this.zone.uuid})
				}
				if (this.$store.state.dragging) {
					this.$store.commit('stopDragging')
				}
				return true
			} else if (this.$store.state.tool === 'select' && interval < 500) {
				console.log(interval)
				// doubleclick
				this.$store.commit('changeTool', {tool: 'seatselect'})
				this.$store.commit('toggleSelection', {uuids: [this.seat.uuid], addition: false, zone: this.zone.uuid})
				return true
			}
			return false
		},
		mousedown (event) {
			if (event.ctrlKey || event.metaKey) {
				// this is a panning event
				return false
			}
			if (this.$store.state.tool === 'seatselect') {
				this.$emit('startDragging', this.seat.uuid, this.zone, event)
				event.stopPropagation()
				return true
			}
			return false
		}
	},
	mounted () {
	},
	destroyed () {
	},
}
</script>
<style lang="stylus">
	.movable .selected g.seat *, .movable g.seat.selected *
		cursor: move
</style>
