<template lang="pug">
#app
	.app-navbar
		toolbar
	.app-main
		.app-canvas-area
			.app-canvas
				plan(ref="plan")
			.app-status-bar
				status-bar()

		.app-properties(v-scrollbar.y="")
			.group-title
				span Zones
					| &nbsp;
					span.mdi.mdi-help-circle.tooltip-large(v-tooltip.bottom="'Zones represent different parts of your venue and are shown on the tickets. You can use them for different levels (ground floor, top floor) or for different areas (Block A, left side, …).'")
				.buffer
				bunt-icon-button(@click="showCreateZonePrompt = true") plus
			.zones
				zone(v-for="z in plan.zones", :zone="z", :key="z.uuid")
			hr
			toolbox
	transition(name="prompt")
		create-zone-prompt(v-if="showCreateZonePrompt", @close="showCreateZonePrompt = false")
		welcome-prompt(v-if="showWelcomePrompt", @close="hideWelcomePrompt")
</template>
<script>
import Zone from 'components/Zone'
import Plan from 'components/canvas/Plan'
import Toolbar from 'components/Toolbar'
import Toolbox from 'components/toolbox/Toolbox'
import CreateZonePrompt from 'components/prompts/CreateZonePrompt'
import StatusBar from 'components/StatusBar'
import WelcomePrompt from './components/prompts/WelcomePrompt'
import {mapGetters} from 'vuex'

const WELCOME_VERSION = '1'

export default {
	components: { Zone, Plan, Toolbar, Toolbox, StatusBar, WelcomePrompt, CreateZonePrompt },
	data () {
		return {
			showCreateZonePrompt: false,
			showWelcomePrompt: window.localStorage.getItem('frontrow2.welcome.seen') !== WELCOME_VERSION,
			cmdOtherwiseCtrl: window.navigator.platform.toLowerCase().startsWith('mac') ? 'CMD' : 'CTRL'
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		hideWelcomePrompt () {
			this.showWelcomePrompt = false
			window.localStorage.setItem('frontrow2.welcome.seen', WELCOME_VERSION)
		},
	}
}
</script>
<style lang="stylus">
	#app
		display: flex
		flex-direction: column
		background: #efefef
		height: 100vh

		.app-navbar
			height: 36px
			min-height: 36px
			background: white
			padding: 5px
			flex: 0
			z-index: 200
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2)

		.app-main
			background: red
			flex: 1
			display: flex
			flex-direction: row
			height: calc(100vh - 48px)
			max-height: calc(100vh - 48px)

			.app-properties
				min-width: 350px
				width: 350px
				flex: 0
				background: #efefef
				box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.2)
				.mdi-help-circle
					color: #999
			.app-canvas-area
				display: flex
				flex-direction: column
				flex: auto 1 1
				background: #333
				.app-canvas
					flex: auto 1 1
					height: calc(100% - 24px)
				.app-status-bar
					overflow: hidden
					width: 100%
					flex-shrink: 0
					flex-grow: 0
					flex-basis: 24px
					height: 24px
					background: #fff
					border-top: 1px solid #ddd
					color: #888
					padding: 4px

	.buffer
		flex: auto
	.group-title
		flex: none
		color: #333
		margin: 16px 8px 0 16px
		height: 28px
		font-weight: 600
		font-size: 12px
		display: flex
		justify-content: space-between
		align-items: center
		.bunt-icon-button
			margin: -4px 0
			icon-button-style(color: #333, style: clear)

	@media print
		.app-navbar, .app-properties, .app-status-bar
			display: none
</style>
