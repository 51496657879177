<template lang="pug">
.c-area-tools
	.toolbox-row
		label Rotation
		number-input.toolbox-input(:value="rotation", name="rotation", @input="setRotation($event)")
	.toolbox-row(v-if="shape === 'rectangle'")
		label Width
		number-input.toolbox-input(:value="width", name="width", @input="setWidth($event)")
	.toolbox-row(v-if="shape === 'rectangle'")
		label Height
		number-input.toolbox-input(:value="height", name="height", @input="setHeight($event)")
	.toolbox-row(v-if="shape === 'circle'")
		label Radius
		number-input.toolbox-input(:value="radius", name="radius", @input="setRadius($event)")
	.toolbox-row(v-if="shape === 'ellipse'")
		label Radius (x)
		number-input.toolbox-input(:value="radiusX", name="radius_x", @input="setRadiusX($event)")
	.toolbox-row(v-if="shape === 'ellipse'")
		label Radius (y)
		number-input.toolbox-input(:value="radiusY", name="radius_y", @input="setRadiusY($event)")
	.toolbox-row(v-if="shape !== 'text'")
		label Color
		chrome(:value="color || '#ccc'", @input="setColor($event.hex)")
	.toolbox-row(v-if="shape !== 'text'")
		label Border
		chrome(:value="borderColor || '#000'", @input="setBorderColor($event.hex)")
	.toolbox-row
		label Text
		bunt-input.toolbox-input(:value="textValue", name="text", @input="setText($event)")
	.toolbox-row
		label Text size
		number-input.toolbox-input(:value="textSize", name="text_size", @input="setTextSize($event)")
	.toolbox-row(v-if="shape !== 'text'")
		label Text position (x)
		number-input.toolbox-input(:value="textX", name="text_x", @input="setTextX($event)")
	.toolbox-row(v-if="shape !== 'text'")
		label Text position (y)
		number-input.toolbox-input(:value="textY", name="text_y", @input="setTextY($event)")
	.toolbox-row
		label Text color
		chrome(:value="textColor || '#333'", @input="setTextColor($event.hex)")
	.toolbox-row
		label Stacking order
		.toolbox-input
			bunt-icon-button(@click="toBack") arrange-send-to-back
			bunt-icon-button(@click="toFront") arrange-bring-to-front
</template>
<script>

import {mapGetters, mapMutations} from "vuex";
import NumberInput from "./fields/NumberInput";
import { Chrome } from 'vue-color'

const groupValue = (areas, mapper) => {
	const uniques = areas.map(mapper).filter((v, i, s) => s.indexOf(v) === i)
	if (uniques.length === 1) {
		return uniques[0]
	} else {
		return undefined
	}
}

export default {
	components: {NumberInput, Chrome},
	props: {
		areas: Array,
	},
	data () {
		return {
		}
	},
	computed: {
		...mapGetters('plan', ['plan']),

		rotation () {
			return groupValue(this.areas, a => a.rotation)
		},
		shape () {
			return groupValue(this.areas, a => a.shape)
		},
		color () {
			return groupValue(this.areas, a => a.color)
		},
		borderColor () {
			return groupValue(this.areas, a => a.border_color)
		},
		textColor () {
			return groupValue(this.areas, a => a.text ? a.text.color : undefined)
		},
		textValue () {
			return groupValue(this.areas, a => a.text ? a.text.text : undefined)
		},
		textSize () {
			return groupValue(this.areas, a => (a.text && a.text.size) ? a.text.size : 16)
		},
		textX () {
			return groupValue(this.areas, a => a.text ? a.text.position.x : 0)
		},
		textY () {
			return groupValue(this.areas, a => a.text ? a.text.position.y : 0)
		},
		width () {
			return groupValue(this.areas, a => a.rectangle ? a.rectangle.width : 0)
		},
		height () {
			return groupValue(this.areas, a => a.rectangle ? a.rectangle.height : 0)
		},
		radius () {
			return groupValue(this.areas, a => a.circle ? a.circle.radius : 0)
		},
		radiusX () {
			return groupValue(this.areas, a => a.ellipse ? a.ellipse.radius.x : 0)
		},
		radiusY () {
			return groupValue(this.areas, a => a.ellipse ? a.ellipse.radius.y : 0)
		},
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		...mapMutations('plan', ['modifyAreas', 'restackAreas']),

		setColor(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), color: val})
		},
		setBorderColor(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), border_color: val})
		},
		setRotation(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), rotation: val})
		},
		setTextColor(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), text__color: val})
		},
		setText(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), text__text: val})
		},
		setTextSize(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), text__size: val})
		},
		setTextX(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), text__position__x: val})
		},
		setTextY(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), text__position__y: val})
		},
		setWidth(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), rectangle__width: val})
		},
		setHeight(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), rectangle__height: val})
		},
		setRadius(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), circle__radius: val})
		},
		setRadiusX(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), ellipse__radius__x: val})
		},
		setRadiusY(val) {
			this.modifyAreas({areaIds: this.areas.map(a => a.uuid), ellipse__radius__y: val})
		},
		toFront (val) {
			this.restackAreas({areaIds: this.areas.map(a => a.uuid), front: true})
		},
		toBack (val) {
			this.restackAreas({areaIds: this.areas.map(a => a.uuid), front: false})
		},
	}
}
</script>
<style lang="stylus">
</style>
