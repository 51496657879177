<template lang="pug">
.c-tree-category
	a
		span.color-indicator(:style="'background: ' + category.color")
		span.name {{ category.name }}
		span.seats(v-if="seats") {{ seats }} seats
		.buffer
		bunt-icon-button(@click.prevent="change") pencil
		bunt-icon-button(@click.prevent="remove") delete
	transition(name="prompt")
		change-category-prompt(v-if="showChangeCategoryPrompt", ref="changeprompt", @close="showChangeCategoryPrompt = false", @save="changed")

</template>
<script>
import ChangeCategoryPrompt from '../prompts/ChangeCategoryPrompt'
export default {
	props: {
		category: Object,
		seats: Number,
	},
	components: {ChangeCategoryPrompt},
	data () {
		return {
			showChangeCategoryPrompt: false
		}
	},
	computed: {
	},
	watch: {},
	mounted () {
	},
	destroyed () {
	},
	methods: {
		change (e) {
			this.showChangeCategoryPrompt = true
			this.$nextTick(() => {
				this.$refs.changeprompt.name = this.category.name
				this.$refs.changeprompt.color = this.category.color
			})
			/*
			let newname = window.prompt('Please choose a new name', this.category.name)
			if (newname) {
				this.$store.commit('plan/renameCategory', {oldname: this.category.name, newname: newname})
			}
			 */
			e.stopPropagation()
			return true
		},
		changed (e) {
			this.$store.commit('plan/changeCategory', {
				oldname: this.category.name,
				newname: this.$refs.changeprompt.name,
				color: this.$refs.changeprompt.color
			})
			this.showChangeCategoryPrompt = false
		},
		remove (e) {
			if (window.confirm('Do you really want to delete this category? All seats currently part of it will no longer be available for sale.')) {
				this.$store.commit('plan/deleteCategory', {name: this.category.name})
			}
			e.stopPropagation()
			return true
		},
	}
}
</script>
<style lang="stylus">
	.c-tree-category
		width: 100%
		.color-indicator
			display: inline-block
			width: 16px
			height: 16px
			margin-right: 4px
			border-radius: 100%
			border: 1px solid #333
			position: relative
			top: 9px
		.name
			display: inline-block
			padding: 10px 0
		.seats
			color: #999
			display: inline-block
			font-weight: normal
			padding: 10px 0 10px 5px
		a
			display: flex
			padding: 0px 16px
			cursor: pointer
			font-weight: bold
		a:hover
			background: #ccc
		a .bunt-icon-button
			visibility: hidden
		a:hover .bunt-icon-button
			visibility: visible
</style>
