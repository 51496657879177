<template lang="pug">
prompt.c-validation-result-prompt(@close="$emit('close')")
	.content
		h1 Validation result
		p(v-if="validationErrors.length === 0") Our validation found no errors in your plan. Good job!
		ul(v-if="validationErrors")
			li.error(v-for="e in validationErrors") {{ e.text }}
</template>
<script>
import Prompt from './Prompt'
import { mapState } from 'vuex'

export default {
	components: { Prompt },
	data () {
		return {
		}
	},
	computed: {
		...mapState('plan', ['validationErrors']),
	},
	methods: {
	}
}
</script>
<style lang="stylus">
.c-validation-result-prompt
	.prompt-wrapper
		width: 620px
	.content
		display: flex
		flex-direction: column
		padding: 32px
		position: relative
		font-size: 16px
		h1
			margin: 0
			text-align: center
</style>
