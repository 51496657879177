export default {
	sampleplan: {
		name: 'Plan',
		categories: [
			{
				name: 'Category I',
				color: '#F44336'
			},
			{
				name: 'Category II',
				color: '#9C27B0'
			},
			{
				name: 'Category III',
				color: '#4CAF50'
			},
			{
				name: 'Category IV',
				color: '#2196F3'
			},
			{
				name: 'Category V',
				color: '#8BC34A'
			}
		],
		zones: [
			{
				name: 'Ground floor',
				position: {
					x: 0,
					y: 0
				},
				rows: [],
				areas: []
			}
		],
		size: {
			width: 900,
			height: 900
		}
	}
}
