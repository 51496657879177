import Vue from 'vue'
import Buntpapier from 'buntpapier'
import Vuelidate from 'vuelidate'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import App from './App.vue'
import store from 'store'
import 'styles/global.styl'
import 'roboto-fontface'
import '@mdi/font/css/materialdesignicons.css'
import sampleplan from './sampleplan'

Vue.config.productionTip = false
Vue.use(Buntpapier)
Vue.use(Vuelidate)
Vue.use(VueVirtualScroller)

window.vapp = new Vue({
	store,
	render: h => h(App)
}).$mount('#app')
store.commit('loadPlan', {
	plan: localStorage.getItem("frontrow2.editor.plan") ? localStorage.getItem("frontrow2.editor.plan") : JSON.stringify(sampleplan.sampleplan)
})

