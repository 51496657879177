<template lang="pug">
prompt.c-welcome-prompt(@close="$emit('close')")
	.content
		h1 Welcome to our seating plan editor!
		p With this tool, you can draw a map of your venue that you can use for your ticket shop.
		p Our graphical editor is pretty new – and can't do every type of plan yet. Most venues with any combination of <strong>straight, diagonal or circular rows</strong> are already possible to create. You can also add <strong>shapes</strong> to highlight the stage, outline a second floor, etc.
		p If you need <strong>tables</strong>, the editor will likely not be good enough for you just yet. In this case, please <a href="https://pretix.eu/control/support/" target="blank">reach out to us</a> and we can create the seating plan for you.
		img(src="../../assets/welcome.svg")
		bunt-button(type="submit", @click="$emit('close')") Let's go!
</template>
<script>
import Prompt from './Prompt'
import { mapState } from 'vuex'

export default {
	components: { Prompt },
	data () {
		return {
		}
	},
	computed: {
		...mapState('plan', ['validationErrors']),
	},
	methods: {
	}
}
</script>
<style lang="stylus">
.c-welcome-prompt
	.prompt-wrapper
		width: 800px
	.content
		display: flex
		flex-direction: column
		padding: 32px
		position: relative
		p
			font-size: 18px
		h1
			margin: 0
			text-align: center
		.bunt-button
			button-style(color: #3b1c4a)
			margin-top: 16px
</style>
