<template lang="pug">
prompt.c-create-zone-prompt(@close="$emit('close')")
	.content
		h1 Create a new zone
		form(@submit.prevent="create")
			bunt-input(name="name", label="Name", placeholder="First floor", v-model="name", :validation="$v.name")
			bunt-button(type="submit") Create
</template>
<script>
import Prompt from './Prompt'
import { required } from 'lib/validators'
import { v4 as uuid } from 'uuid'

export default {
	components: { Prompt },
	data () {
		return {
			name: '',
		}
	},
	validations: {
		name: {
			required: required('Name is required')
		}
	},
	methods: {
		create () {
			this.$v.$touch()
			if (this.$v.$invalid) return
			let id = uuid()
			this.$store.commit('plan/createZone', {
				name: this.name,
				uuid: id,
			})
			this.$store.commit('selectZone', {
				uuid: id
			})
			this.$emit('close')
		}
	}
}
</script>
<style lang="stylus">
.c-create-zone-prompt
	.content
		display: flex
		flex-direction: column
		padding: 32px
		position: relative
		h1
			margin: 0
			text-align: center
		p
			max-width: 320px
		form
			display: flex
			flex-direction: column
			align-self: stretch
			.bunt-button
				button-style(color: $clr-primary)
				margin-top: 16px
</style>
